import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter, horizonalRule } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const ResearchPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Therapeutic Focus: Research and Development" />
      <RunReCaptcha action="focus" />
      <div className={gutter}>
        <h1>Research &#38; Development</h1>

        <h2>Therapeutic Focus</h2>
        <h3>Overview</h3>
        <p>
          Preclinical studies have shown that tetrac-based molecules produced by
          the Company are extremely effective against all human cancer models
          tested in mice. These cancers include glioblastoma (GBM), acute
          myeloid leukemia (AML), neuroblastoma, breast, prostate, pancreas,
          kidney, lung, bladder, cervical, melanoma and thyroid cancers. It is
          important to note that treatment of various orthotopic or xenografted
          tumors resulted in &gt;90% shrinkage, with residual apoptotic tumor
          tissue that continues to be eliminated without any relapse, unlike
          most other strategies. Additionally, the broad-spectrum
          anti-angiogenesis activities and the inhibitory platelet activities by
          the high affinity tetrac-modified molecules eliminate existing or
          prevent any additional tumor metastasis. This effectiveness has been
          demonstrated without any detectable adverse effects in animals at
          doses 100-fold greater than those proposed for use in human subjects.
        </p>

        <hr className={horizonalRule} />

        <h3>Glioblastoma</h3>
        <p>
          The Company’s preclinical studies with fb-PMT established that
          subcutaneously administered fb-PMT is a highly effective intervention
          in the treatment of glioblastoma (GBM). First, and foremost, our
          compound is taken up by the transthyretin (prealbumin) transport
          mechanism and readily passes through the blood-brain barrier into the
          brain. fb-PMT immediately travels to the tumor and begins attacking
          the tumor’s vasculature. GBM is a highly vascular tumor associated
          with edema formation. The compound’s antiangiogenic effects destroy
          the tumor’s blood vessels, reducing the oxygen and nutrients needed by
          the tumor to survive and eliminating the leakage, thus reducing and/or
          eliminating the edema. Simultaneously, the pro-apoptotic effects begin
          triggering spontaneous cell death in the tumor. During the therapy,
          the agent reduced tumor weight and tumor cell viability by 90%, with
          no signs of intratumoral hemorrhage observed. fb-PMT works on a large
          group of cancer cell signaling pathways (e.g., Hedgehog, Notch,
          HIPPO/YAP, mTOR), interferes with multiple pathways mediating
          essential molecular functions such as the KRAS oncogenic pathway, and
          downregulates expression of several key cancer driver genes.
        </p>
        <p>
          Following discontinuation of fb-PMT, tumors in treated animals did not
          re-grow over a 3-week period of observation.
        </p>
        <p>
          A Phase I clinical trial with fb-PMT for glioblastoma is ongoing at
          Yale Cancer Center.
        </p>

        <hr className={horizonalRule} />

        <h3>Pancreatic Cancer</h3>
        <p>
          fb-PMT proved to be extremely effective against pancreatic cancer and
          its metastases in numerous preclinical studies. It significantly
          reduced tumor weight and tumor viability by 90% or greater after 3
          weeks of treatment with no relapse after treatment was discontinued.
          Furthermore, the compound was effective in preventing and eliminating
          metastases from the orthotopic primary xenograft to spleen, liver,
          kidney, lung, and other organs. By such elimination, the patient is
          open to a much wider array of treatment modalities, including surgery
          and radiotherapy, otherwise not available for a patient with distant
          metastases.
        </p>
        <p>
          NanoPharmaceuticals anticipates conducting a Phase 2 clinical trial
          with fb-PMT for the treatment of pancreatic cancer after the Phase I
          trial for glioblastoma is completed.
        </p>

        <hr className={horizonalRule} />

        <h3>Other Solid Tumors and Metastases</h3>
        <p>
          The target of the Company’s compounds on integrin αvβ3 is linked by a
          number of downstream mechanisms to regulation of transcription of
          specific genes and to functions of cell surface vascular growth factor
          receptors. At this target, tetrac-based formulations coherently
          trigger more than 50 effects in cancer cells. Preclinical studies have
          shown they disrupt cancer-linked angiogenesis, block cancer cell
          division and DNA repair, induce programmed cell death (apoptosis), and
          disorder expression of a panel of genes essential to cancer cell
          defenses. Tetrac-based formulations in preclinical studies have caused
          extensive devascularization, cell necrosis and apoptosis in twelve
          different human cancers grafted into animals. These cancers include
          glioblastoma (GBM), acute myeloid leukemia (AML; see below),
          neuroblastoma, cancers of the breast, prostate, pancreas, kidney,
          lung, bladder and ovary, as well as melanoma and several forms of
          thyroid cancers. A number of cancer driver genes implicated in
          development of metastases are downregulated by tetrac-containing
          products. The interaction of platelets and circulating tumor cells,
          tumor angiogenesis, cancer cell migration and invasion contribute to
          metastasis development and these interactions are blocked by
          tetrac-based products.{" "}
        </p>

        <hr className={horizonalRule} />

        <h3>Hematologic malignancies</h3>
        <h4>Acute Myeloid Leukemia (AML)</h4>
        <p>
          Our preclinical studies have shown that our lead clinical candidate
          fb-PMT effectively suppresses the progression of an AML cell line and
          primary AML cells harboring FLT3-ITD in a transgenic NSG mouse model.
          Daily treatment with fb-PMT for 3-4 weeks was associated with
          leukemogenesis regression, suppression of cancer invasion and extended
          survival. Peripheral blood counts and smears from fb-PMT treated
          animals at 10 mg/kg returned to normal. Bone marrow biopsies regained
          their normal maturation with abundant segmented neutrophil and
          megakaryocytes; this represented complete hematological remission
          along with complete suppression of leukemic cell metastasis and
          invasion into different organs. These effects were maintained in
          animals after treatment with fb-PMT was discontinued for 2 weeks.
        </p>

        <hr className={horizonalRule} />

        <h3>Neuroendocrine tumors</h3>
        <p>
          BG-P-TAT, a dual norepinephrine transporter polymer conjugated to a
          thyrointegrin αvβ3 antagonist via a non-cleavable bond, was developed
          to target and treat neuroendocrine tumors such as neuroblastoma,
          pheochromocytoma, pancreatic neuroendocrine tumor, and carcinoid
          tumor.
        </p>
        <h4>Neuroblastoma</h4>
        <p>
          Neuroblastoma is an extracranial childhood cancer that originates in
          embryonal remnants of the sympathetic nervous system (neural crest
          cells). In animal model studies, BG-P-TAT reduced tumor volumes by
          more than 95% after 3-4 weeks of treatment. Furthermore, tumor
          regression continued after 1 week of discontinued treatment to
          effectively complete eradication of the neuroblastoma tumors. The
          reduction in tumor size with BG-P-TAT administration is a function of
          anti-tumor activity and clearance of tumor debris. BG-P-TAT
          effectively modulated key molecular pathways involved in
          neuroendocrine tumor progression including VEGFA-VEGFR2, EGFR,
          TOLL-like receptor, IL1α, TNFβ and RNAKL/RNAK/NF-kB signaling
          pathways, all in a coherent manner.
        </p>

        <hr className={horizonalRule} />

        <h3>Pathological Angiogenesis-Associated Disorders</h3>
        <p>
          Pathological angiogenesis-associated disorders are disorders caused by
          abnormally excessive angiogenesis. Examples of these disorders include
          diseases of the eye and nonmalignant skin disease.
        </p>
        <p>
          Angiogenesis that creates excess blood vessels in the eye is a
          principal component of eye diseases such as diabetic retinopathy and
          wet age-related macular degeneration. The Company’s investigators and
          collaborators have determined that its tetrac-based molecules could
          significantly inhibit the action of vascular growth factors that
          stimulate this angiogenesis in the eye, based on pre-clinical studies.
        </p>
        <p>
          Highly vascular nonmalignant skin diseases, usually with a
          pro-inflammatory component, are potential targets for management with{" "}
          <em>topical</em> preparations of the Company’s tetrac-based
          pharmaceuticals. These skin disorders include rosacea, poikiloderma
          and psoriasis. Tetrac-based pharmaceuticals inhibit the various
          pro-angiogenic growth factors that are the principal contributors to
          these skin diseases. They also have anti-inflammatory properties that
          provide added benefit for the treatment of these skin diseases by
          inhibiting the expression of multiple genes of pro-inflammatory
          cytokines—such as interleukin-1α (IL-1α), IL-1β and IL-6—and
          chemokines. The anti-inflammatory properties of tetrac-based molecules
          could provide added benefit for the treatment of these skin diseases.
        </p>
      </div>
    </Layout>
  );
};

export default ResearchPage;
